import styled from 'styled-components'
import { Media, typography } from '../../../common/theme'

export const SpringBannerWrapper = styled.div`
  position: relative;
  z-index: 0;
  color: #fff;
  height: 100vh;
  text-align: center;
  font-size: ${typography.h4};
  .active-img {
    height: 351px;
    width: 695px;
    margin-top: 175px;
    margin-right: 15px;
  }
  .active-img2 {
    display: none;
  }
  .date-text {
    position: relative;
    top: 165px;
    text-align: center;
    font-weight: 400;
    font-size: ${typography.h4};
    color: #fdfbed;
  }
  .date-text::before {
    content: '';
    display: inline-block;
    width: 100px;
    height: 4px;
    background: linear-gradient(90deg, rgba(215, 44, 37, 0) 0%, #fde6c8 100%);
    margin-right: 16px;
    vertical-align: middle;
  }
  .date-text::after {
    content: '';
    display: inline-block;
    width: 100px;
    height: 4px;
    background: linear-gradient(270deg, rgba(215, 44, 37, 0) 0%, #fde6c8 100%);
    margin-left: 16px;
    vertical-align: middle;
  }
  ${Media.lessThan(Media.small)} {
    font-size: 15px;
    height: 580px;
    .date-text {
      top: 136px;
    }
    .active-img2 {
      display: block;
      height: 193px;
      width: 347px;
      margin: 140px auto 0;
    }
    .active-img {
      display: none;
    }
    .date-text::before {
      width: 75px;
      hieght: 3px;
    }
    .date-text::after {
      width: 75px;
      hieght: 3px;
    }
  }
`

export const Learn = styled.div`
  color: #cb3133;
  font-size: 20px;
  margin-top: 15px;
  display: flex;
  height: 49px;
  align-items: center;
  justify-content: center;
  position: relative;
  .btn-box-hover {
    display: none;
  }
  :hover {
    .btn-box-default {
      display: none;
    }
    .btn-box-hover {
      display: block;
      position: absolute;
      top: -12px;
    }
  }
  ::after {
    content: '立即领取';
    position: absolute;
  }
  ${Media.lessThan(Media.small)} {
    top: 17px;
    .btn-box1 {
      width: 150px;
      height: 38px;
    }
  }
`

export const SpringTitle = styled.div`
  text-align: center;
`

export const SpringExclusive = styled.div`
  height: 2220px;
  background-color: #df3a35;
  position: relative;
  img {
    position: absolute;
    width: 188px;
    height: 200px;
  }
  .red-bag1 {
    left: 71px;
    top: 80px;
  }
  .red-bag2 {
    right: 61px;
    top: 295px;
  }
  .money1 {
    left: 69px;
    top: 980px;
  }
  .money2 {
    right: 85px;
    top: 1394px;
  }
  .bgbox1 {
    left: 50%;
    margin-left: -640px;
    width: 1281px;
    height: 521px;
  }
  .bgbox2 {
    left: 50%;
    top: 32%;
    margin-left: -640px;
    width: 1281px;
    height: 1183px;
  }
  .btn-box1 {
    width: 387px;
    height: 97px;
    left: 50%;
    margin-left: -194px;
    top: -26px;
  }
  .btn-box2 {
    width: 387px;
    height: 97px;
    left: 50%;
    margin-left: -194px;
    top: 676px;
  }
  span {
    position: relative;
    font-size: 36px;
    font-weight: 500;
    color: #cb3133;
    top: -10px;
  }
  .old-new {
    top: 640px;
  }
  .coupon1 {
    width: 725px;
    height: 281px;
    left: 50%;
    margin-left: -362px;
    top: 137px;
  }
  .coupon2,
  .bgbox2-phone {
    display: none;
  }
  ${Media.lessThan(Media.small)} {
    height: 920px;
    .red-bag1,
    .red-bag2,
    .money1,
    .money2,
    .coupon1,
    .bgbox2 {
      display: none;
    }
    .bgbox1 {
      left: 50%;
      margin-left: -173px;
      width: 345px;
      height: 210px;
      top: -46px;
    }
    .bgbox2 {
      left: 50%;
      top: 35%;
      margin-left: -640px;
      width: 345px;
      height: 500px;
    }
    .btn-box1 {
      width: 193px;
      height: 48px;
      left: 50%;
      margin-left: -98px;
      top: -60px;
    }
    .coupon2 {
      display: block;
      width: 260px;
      height: 90px;
      left: 50%;
      margin-left: -130px;
      top: 20px;
    }
    span {
      font-size: 22px;
      top: -53px;
    }
    .bgbox2-phone {
      display: block;
      width: 345px;
      height: 397px;
      left: 50%;
      margin-left: -172px;
      top: 260px;
    }
    .btn-box2 {
      width: 193px;
      height: 48px;
      left: 50%;
      margin-left: -98px;
      top: 244px;
    }
    .old-new {
      top: 216px;
    }
  }
`

export const Frame = styled.div`
  width: 1061px;
  height: 221px;
  background: rgba(249, 216, 183, 0.05);
  border: 1px solid #f8c38d;
  border-radius: 20px;
  position: relative;
  left: 50%;
  margin-left: -530px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  ${Media.lessThan(Media.small)} {
    width: 375px;
    border: none;
    height: 47px;
    margin-left: -187px;
    background: none;
  }
`

export const Recharge = styled.div`
  width: 200px;
  height: 160px;
  background: linear-gradient(0deg, #f7c28c 0%, #fddfbd 53%, #fff7e7 100%);
  box-shadow: 0px 10px 16px 4px rgba(183, 55, 26, 0.35);
  border-radius: 20px;
  padding: 10px;
  margin-left: 22px;
  ${Media.lessThan(Media.small)} {
    width: 66px;
    height: 51px;
    border-radius: 10px;
    padding: 3px;
    margin-left: 34px;
  }
`

export const RedBorder = styled.div`
  width: 180px;
  height: 140px;
  border-radius: 16px;
  background-image: linear-gradient(0deg, #f66042 0%, #cb3133 99%);
  padding: 3px;
  ${Media.lessThan(Media.small)} {
    width: 60px;
    height: 46px;
    border-radius: 8px;
    padding: 1px;
  }
`

export const Internal = styled.div`
  border-radius: 16px;
  background: linear-gradient(0deg, #f7c28c 0%, #fddfbd 53%, #fff7e7 100%);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  span {
    width: 97px;
    height: 63px;
    font-size: 30px;
    font-family: Source Han Sans CN;
    font-weight: 800;
    font-style: italic;
    color: #fefefe;
    line-height: 34px;
    background: linear-gradient(0deg, #f66042 0.146484375%, #cb3133 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding: 10px;
  }
  ${Media.lessThan(Media.small)} {
    span {
      width: 32px;
      height: 20px;
      font-size: 10px;
      line-height: 10px;
      top: 0px;
      padding: 2px;
    }
    border-radius: 8px;
  }
`

export const Deliver = styled.div`
  width: 32px;
  height: 60px;
  font-size: 30px;
  font-family: Source Han Sans CN;
  font-weight: 800;
  font-style: italic;
  color: #f7c28c;
  text-shadow: 0px 0px 9px rgba(183, 55, 26, 0.75);
  background: linear-gradient(0deg, #fff6e6 0%, #f7c38e 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-left: 30px;
  margin-right: 13px;
  ${Media.lessThan(Media.small)} {
    width: 20px;
    height: 20px;
    font-size: 10px;
    margin-left: 8px;
    margin-right: 0px;
  }
`

export const Member = styled.div`
  img {
    position: relative;
    width: 403px;
    ${Media.lessThan(Media.small)} {
      width: 105px;
      height: 58px;
    }
  }
`

export const Add = styled.div`
  img {
    position: relative;
    width: 50px;
    height: 50px;
    margin-right: 12px;
    ${Media.lessThan(Media.small)} {
      width: 16px;
      height: 16px;
      margin-right: 7px;
    }
  }
`

export const Coupon = styled.div`
  img {
    position: relative;
    width: 270px;
    height: 160px;
    ${Media.lessThan(Media.small)} {
      width: 85px;
      height: 48px;
    }
  }
`

export const SpringMember = styled.div`
  margin-top: 710px;
  ${Media.lessThan(Media.small)} {
    margin-top: 259px;
  }
`

export const SpringFooter = styled.div`
  position: absolute;
  left: 50%;
  top: 86%;
  margin-left: -580px;
  height: 162px;
  font-size: ${typography.h4};
  font-weight: 500;
  color: #ffffff;
  line-height: 48px;
  ${Media.lessThan(Media.small)} {
    width: 332px;
    margin-left: -166px;
    font-size: 15px;
    top: 73%;
    line-height: 30px;
  }
`
