import React from 'react'
import { Layout } from '@dayancloud/common/components'
import { TopContainer } from './index.atom'
// import useMedia from 'use-media'
import { Billboard } from '@dayancloud/common/components'
import springActive from '../images/index/spring-active.png'
import springActivePhone from '../images/index/spring-active-phone.png'
import btnBg from '../images/index/btn-bg.png'
import btnBox1 from '../images/index/btn-box1.png'
import btnBox from '../images/index/btn-box.png'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import Redbag1 from '../images/index/red-bag1.png'
import Redbag2 from '../images/index/red-bag2.png'
import Money1 from '../images/index/money1.png'
import Money2 from '../images/index/money2.png'
import Bgbox1 from '../images/index/bg-box1.png'
import Bgbox2 from '../images/index/bg-box2.png'
import Bgbox2phone from '../images/index/bg-box2-phone.png'
import Coupon1 from '../images/index/coupon1.png'
import Sliver from '../images/index/sliver.png'
import Gold from '../images/index/gold.png'
import Platinum from '../images/index/platinum.png'
import Diamond from '../images/index/diamond.png'
import addImg from '../images/index/add.png'
import couponImg from '../images/index/coupon.png'
import Coupon2 from '../images/index/coupon2.png'
import {
  SpringBannerWrapper,
  Learn,
  SpringTitle,
  SpringExclusive,
  SpringMember,
  SpringFooter,
  Frame,
  Recharge,
  RedBorder,
  Internal,
  Deliver,
  Member,
  Add,
  Coupon,
} from './springinfo.atom'

class Springinfo extends React.PureComponent {
  state = {
    headerBg: '#FFFFFF',
    isBorder: true,
  }
  handleScroll = (e) => {
    if (e.currentTarget.scrollY) {
      this.setState({ isBorder: false })
    } else {
      this.setState({ isBorder: true })
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, true)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, true)
  }
  render() {
    const {
      data,
      location,
      pageContext: { topFiveNews },
    } = this.props
    return (
      <Layout
        location={location}
        topFiveNews={topFiveNews}
        showFriendLink={false}
        headerBg={this.state.headerBg}
        isBorder={this.state.isBorder}
      >
        <TopContainer>
          <SpringBannerWrapper>
            <div className='date-text'>2023年2月1日-3月31日</div>
            <img className='active-img' src={springActive} alt='' />
            <img className='active-img2' src={springActivePhone} alt='' />
            <div>
              <Learn as={Link} to='https://account.dayancloud.com/center/user/topUp'>
                <img className='btn-box-default' src={btnBox1} alt='' />
                <img className='btn-box-hover' src={btnBox} alt='' />
              </Learn>
            </div>
            <Billboard
              fluid={[
                data.bannerMobile.childImageSharp.fluid,
                { ...data.banner.childImageSharp.fluid, media: '(min-width: 780px)' },
              ]}
            />
          </SpringBannerWrapper>
          <SpringExclusive>
            <img className='red-bag1' src={Redbag1} alt='' />
            <img className='red-bag2' src={Redbag2} alt='' />
            <img className='money1' src={Money1} alt='' />
            <img className='money2' src={Money2} alt='' />
            <img className='bgbox1' src={Bgbox1} alt='' />
            <SpringTitle>
              <img className='btn-box1' src={btnBg} alt='' />
              <span>新用户专享</span>
            </SpringTitle>
            <img className='coupon1' src={Coupon1} alt='' />
            <img className='coupon2' src={Coupon2} alt='' />
            <img className='bgbox2' src={Bgbox2} alt='' />
            <img className='bgbox2-phone' src={Bgbox2phone} alt='' />
            <SpringTitle>
              <img className='btn-box2' src={btnBg} alt='' />
              <span className='old-new'>新老用户专享</span>
            </SpringTitle>
            <SpringMember>
              <Frame>
                <Recharge>
                  <RedBorder>
                    <Internal>
                      <div>
                        <span>充值</span>
                      </div>
                      <div>
                        <span>满99元</span>
                      </div>
                    </Internal>
                  </RedBorder>
                </Recharge>
                <Deliver>送</Deliver>
                <Member>
                  <img src={Sliver} alt='' />
                </Member>
                <Add>
                  <img src={addImg} alt='' />
                </Add>
                <Coupon>
                  <img src={couponImg} alt='' />
                </Coupon>
              </Frame>
              <Frame>
                <Recharge>
                  <RedBorder>
                    <Internal>
                      <div>
                        <span>充值</span>
                      </div>
                      <div>
                        <span>满699元</span>
                      </div>
                    </Internal>
                  </RedBorder>
                </Recharge>
                <Deliver>送</Deliver>
                <Member>
                  <img src={Gold} alt='' />
                </Member>
                <Add>
                  <img src={addImg} alt='' />
                </Add>
                <Coupon>
                  <img src={couponImg} alt='' />
                </Coupon>
              </Frame>
              <Frame>
                <Recharge>
                  <RedBorder>
                    <Internal>
                      <div>
                        <span>充值</span>
                      </div>
                      <div>
                        <span>满3999元</span>
                      </div>
                    </Internal>
                  </RedBorder>
                </Recharge>
                <Deliver>送</Deliver>
                <Member>
                  <img src={Platinum} alt='' />
                </Member>
                <Add>
                  <img src={addImg} alt='' />
                </Add>
                <Coupon>
                  <img src={couponImg} alt='' />
                </Coupon>
              </Frame>
              <Frame>
                <Recharge>
                  <RedBorder>
                    <Internal>
                      <div>
                        <span>充值</span>
                      </div>
                      <div>
                        <span>满8888元</span>
                      </div>
                    </Internal>
                  </RedBorder>
                </Recharge>
                <Deliver>送</Deliver>
                <Member>
                  <img src={Diamond} alt='' />
                </Member>
                <Add>
                  <img src={addImg} alt='' />
                </Add>
                <Coupon>
                  <img src={couponImg} alt='' />
                </Coupon>
              </Frame>
            </SpringMember>
            <SpringFooter>
              <p>
                活动说明：
                <br />
                1. 活动赠送的代金券有效期为3个月
                <br />
                2. 充值满额后会员及代金券将自动到账，赠送的会员到期后即将按照官网的会员等级计算
                <br />
                3. 本活最终动解释权归大雁云所有
                <br />
              </p>
            </SpringFooter>
          </SpringExclusive>
        </TopContainer>
      </Layout>
    )
  }
}

export default Springinfo

export const query = graphql`
  query {
    banner: file(relativePath: { regex: "/index/spring-actives-bg.png/" }) {
      ...fluidImage
    }
    bannerMobile: file(relativePath: { regex: "/index/bg-phone.png/" }) {
      ...fluidImage
    }
  }
`
